import axios from 'src/app-axios';
import Constants from 'src/constants';

export const projectgates = {
    namespaced: true,
    state: {
      markedProjectGateDetails: {},
      projectGatesList: [],
      savedProjectGateDetails: {},
      createdProjectGatesStructure: [],
      savedProjectGatesStructure: {},
      projectGateCheckIns: [],
      projectGateDetails: {},
      filteredProjectGates: [],
    },

    getters: {
      getMarkedProjectGateDetails (state) {
        return state.markedProjectGateDetails;
      },
      getProjectGatesList (state) {
        return state.projectGatesList;
      },
      getSavedProjectGateDetails (state) {
        return state.savedProjectGateDetails;
      },
      getCreatedProjectGatesStructure (state) {
        return state.createdProjectGatesStructure;
      },
      getSavedProjectGatesStructure (state) {
       return state.savedProjectGatesStructure;
      },
      getProjectGateCheckIns (state) {
        return state.projectGateCheckIns;
      },
      getProjectGateDetails (state) {
        return state.projectGateDetails;
      },
      getFilteredProjectGates (state) {
        return state.filteredProjectGates;
      },
    },
    mutations: {
      setMarkedProjectGateDetails (state, data) {
        state.markedProjectGateDetails = data;
      },
      clearMarkedProjectGateDetails (state) {
        state.markedProjectGateDetails = {};
      },
      setProjectGatesList (state, data) {
        state.projectGatesList = data;
      },
      setSavedProjectGateDetails (state, details) {
        state.savedProjectGateDetails = details;
      },
      clearSavedProjectGateDetails (state) {
        state.savedProjectGateDetails = {};
      },
      setCreatedProjectGatesStructure (state, data) {
        state.createdProjectGatesStructure = data;
      },
      setSavedProjectGatesStructure (state, data) {
        state.savedProjectGatesStructure = data;
      },
      setProjectGateCheckIns (state, data) {
        state.projectGateCheckIns = data;
      },
      setProjectGateDetails (state, details) {
        state.projectGateDetails = details;
      },
      setFilteredProjectGates (state, gates) {
        state.filteredProjectGates = gates;
      },
    },
    actions: {
      storeMarkedProjectGateDetails ({ commit }, details) {
        commit('setMarkedProjectGateDetails', details);
      },
      removeMarkedProjectGateDetails ({ commit }) {
        commit('clearMarkedProjectGateDetails');
      },
      async fetchProjectGatesList ({ dispatch, commit }) {
        const url = 'api/project-gates';
        try {
          const response = await axios.get(url);
          commit('setProjectGatesList', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting your project gates list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async fetchProjectGatesByProject ({ dispatch, commit }, payload) {
        const url = 'api/project-gates';
        try {
          const response = await axios.get(url, payload);
          commit('setProjectGatesList', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the filtered project gates list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async fetchFilteredProjectGates ({ dispatch, commit }, payload) {
        const url = 'api/project-gates';
        try {
          const response = await axios.get(url, payload);
          commit('setFilteredProjectGates', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the filtered project gates list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      saveProjectGateDetails ({ commit }, details) {
        commit('setSavedProjectGateDetails', details);
      },
      removeSavedProjectGateDetails ({ commit }) {
        commit('clearSavedProjectGateDetails');
      },
      async addNewProjectGate ({ dispatch }, payload) {
        const url = 'api/project-gates';
        try {
          await axios.post(url, payload.projectGateDetails);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in adding your new project gate.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      createProjectGatesStructure (context, payload) {
        context.commit('setCreatedProjectGatesStructure', payload);
      },
      async fetchSavedProjectGatesStructure ({ dispatch, commit }) {
        const url = 'api/project-gates/group';
        try {
            const details = await axios.get(url);
            commit('setSavedProjectGatesStructure', details.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your grouped project gates list.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async groupProjectGates  ({ dispatch, commit }, payload) {
        const url = 'api/project-gates/group';
        try {
            await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating the project gates group.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async fetchProjectGateCheckIns ({ dispatch, commit }, payload) {
        const url = 'api/records';
        try {
            const records = await axios.get(url, payload.params);
            commit('setProjectGateCheckIns', records.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting the project gate check-in records list.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
    },
      async deleteProjectGate ({ dispatch }, payload) {
        const url = `api/project-gates/${payload.projectGateId}`;
        try {
            await axios.delete(url);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in deleting this project gate.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      async updateProjectGate ({ dispatch }, payload) {
        const url = `api/project-gates/${payload.projectGateId}`;
        try {
          await axios.put(url, payload.projectGateDetails);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in updating this project gate.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      async fetchProjectGateDetails ({ dispatch, commit }, payload) {
        const url = `api/project-gates/${payload.projectGateId}`;
        try {
          const response = await axios.get(url);
          commit('setProjectGateDetails', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the project gate details.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
    },
};
